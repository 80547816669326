import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';
import Slider from 'react-slick';
import get from 'lodash/get';
import { GatsbyImage } from 'gatsby-plugin-image';
import { styleConfig } from '../../config/config';
import Section from './Common/Section';
import SliderNavigation from './SliderNavigation';
import Title from './Common/Title';

const ContentModuleImageSlider: React.FunctionComponent<ContentModuleImageSliderProps> = (
  props
) => {
  const { slides, title, subtitle } = props;

  const [slideIndex, setSlideIndex] = useState(0);
  const slidesLength = (slides && slides.length) || 0;

  const slider = useRef(null);

  const handleNext = () => {
    // @ts-ignore: Unknown Slick Slider ref type
    slider.current.slickGoTo(slideIndex < slidesLength - 1 ? slideIndex + 1 : slideIndex);
    setSlideIndex(slideIndex < slidesLength - 1 ? slideIndex + 1 : slideIndex);
  };

  const handlePrev = () => {
    // @ts-ignore: Unknown Slick Slider ref type
    slider.current.slickGoTo(slideIndex > 0 ? slideIndex - 1 : slideIndex);
    setSlideIndex(slideIndex > 0 ? slideIndex - 1 : slideIndex);
  };

  const settings = {
    dots: false,
    draggable: false,
    infinite: false,
    pagination: false,
    slidesToScroll: 1,
    slidesToShow: 1.9999,
    swipeToSlide: false,
    lazyLoad: 'ondemand',
    swipe: false,
    responsive: [
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const useStyles = makeStyles((theme: Theme) => ({
    noPadding: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
    title: {
      display: 'block',
      marginBottom: '6rem',
    },
    slider: {
      position: 'relative',
      maxWidth: '100%',
      overflowX: 'hidden',
      '& .slick-slide': {
        padding: '0 40px 0 0',
        marginLeft: '1.15%',
        marginRight: '-1.15%',

        [theme.breakpoints.down('sm')]: {
          marginLeft: '0.6944%',
          marginRight: '-0.6944%',
          paddingLeft: 0,
        },
      },
      '& .slick-prev': {
        left: '-25px',
        bottom: 0,
        position: 'absolute',
      },
    },
    nav: {
      paddingLeft: '3.5%',
    },
    slide: {
      width: '100%',
      maxHeight: 800,
    },
  }));

  const classes = useStyles();

  return (
    slides && (
      <Section>
        {!isNull(title) && !isNull(subtitle) && title && (
          <Box className={classes.title}>
            <Title
              component="h2"
              alignment="center"
              isSubtitle
              subtitleText={subtitle}
              subtitleType="subtitle1"
              titleCaps
              titleColor={styleConfig.color.text.darkgrey}
              titleHeavy
              titleSize="h2"
              titleText={title}
            />
          </Box>
        )}

        <div className={classes.slider}>
          <Slider {...settings} ref={slider}>
            {slides &&
              slides.map((slide, index) => {
                const image = get(slide, 'localFile.childImageSharp.gatsbyImageData');

                if (image) {
                  return (
                    <div key={`cm-image-slider-${index}`} className={classes.slide}>
                      <GatsbyImage alt="" image={image} loading="eager" />
                    </div>
                  );
                }
              })}
          </Slider>

          {slidesLength >= 2 && (
            <div className={classes.nav}>
              <SliderNavigation
                handleNext={handleNext}
                handlePrev={handlePrev}
                showCount
                slideIndex={slideIndex}
                slidesTotal={slidesLength}
                style="dark"
              />
            </div>
          )}
        </div>
      </Section>
    )
  );
};

interface Image {
  localFile: {
    childImageSharp: {
      gatsbyImageData: any;
    };
  };
}

interface ContentModuleImageSliderProps {
  title?: string;
  subtitle: string;
  slides: Image[];
}

interface RefObject<T> {
  readonly current: T | null;
}

export default ContentModuleImageSlider;
